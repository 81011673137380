import React from 'react'

const NotFound = () => {
  return (
    <div style={{ height: '100%' }} className='d-flex py-4'>
      <div className='w-100 m-auto text-center'>
        <h5 className='text-secondary'>PAGE NOT FOUND!</h5>
      </div>
    </div>
  )
}

export default NotFound