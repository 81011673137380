import React, { useEffect } from 'react';

const Redirect = () => {
  useEffect(() => {
    setTimeout((ms, mk) => {
      console.log(mk);
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSeqKcJ5qQ2DC9OYF7NMdj1hhvDce44W5PYPcI_wdr_xMZgAhQ/viewform')
    }, 1000);
  }, []);

  return (
    <div className='container mt-5'>
      <div className='text-center'>
        <p>Redirect...</p>
      </div>
    </div>
  )
}

export default Redirect