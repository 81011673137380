const api = (() => {
  const {
    REACT_APP_PROTOCOL,
    REACT_APP_HOST,
    REACT_APP_PORT,
  } = process.env;
  const BASE_URL = `${REACT_APP_PROTOCOL}://${REACT_APP_HOST}:${REACT_APP_PORT}`;

  function putAccessToken(token) {
    localStorage.setItem('accessToken', token);
  }

  function getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  function putRefreshToken(token) {
    return localStorage.setItem('refreshToken', token);
  }

  function getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  function deleteToken() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  async function _fetchWithAuth(url, options = {}) {
    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  }

  async function register({ name, email, password }) {
    const response = await fetch(`${BASE_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        password,
      }),
    });

    const responseJson = await response.json();
    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return { status };
  }

  async function login({ email, password }) {
    const response = await fetch(`${BASE_URL}/authentications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function refreshToken() {
    const response = await fetch(`${BASE_URL}/authentications`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: `${getRefreshToken()}`,
      }),
    });

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }
    putAccessToken(data.accessToken);
  }

  async function getUsers({ page, pageSize }) {
    const response = await fetch(`${BASE_URL}/iysf?pageSize=${pageSize}&page=${page}`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getUsersByOrderId({ orderId }) {
    const response = await fetch(`${BASE_URL}/iysf/${orderId}`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    // if (status !== 'success') {
    //   throw new Error(message);
    // }

    return { status, message, data };
  }

  async function postUsersByOrderId({ orders }) {
    const response = await fetch(`${BASE_URL}/iysf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orders),
    });

    const responseJson = await response.json();
    const { status, message } = responseJson;

    // if (status !== 'success') {
    //   throw new Error(message);
    // }

    return message;
  }

  async function putUsersStatusAttendByOrderId({ orderId }) {
    const response = await fetch(`${BASE_URL}/iysf/${orderId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }
  // Meals
  async function getMeals({ page, pageSize }) {
    const response = await fetch(`${BASE_URL}/iysf/meals?pageSize=${pageSize}&page=${page}`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getMealsByUsersId({ id }) {
    const response = await fetch(`${BASE_URL}/iysf/meals/${id}`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function postMealUsersByOrderId({ id, mealsId }) {
    const response = await fetch(`${BASE_URL}/iysf/meals/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mealsId }),
    });

    const responseJson = await response.json();
    const { status, message } = responseJson;

    // if (status !== 'success') {
    //   throw new Error(message);
    // }

    return message;
  }

  return {
    putAccessToken,
    getAccessToken,
    putRefreshToken,
    getRefreshToken,
    refreshToken,
    deleteToken,
    register,
    login,
    getUsers,
    getUsersByOrderId,
    postUsersByOrderId,
    putUsersStatusAttendByOrderId,
    // Meals
    getMeals,
    getMealsByUsersId,
    postMealUsersByOrderId,
  };
})();

export default api;
