const media = [
  {
    no: 1,
    date: "7 September 2024",
    headline: "Indonesia Youth Sustainability 2024, Forum Diskusi Isu Sosial dan Lingkungan",
    type: "Televisi",
    media: "TV One",
    url: "https://youtu.be/LCxeh8rcO50? feature=shared",
    tone: "Positif",
    tier: 1
  },
  {
    no: 2,
    date: "7 September 2024",
    headline: "Bakrie Center Foundation Gelar Indonesia Youth Sustainability 2024",
    type: "Televisi",
    media: "TV One",
    url: "https://youtu.be/AHcdzAf_pzs",
    tone: "Positif",
    tier: 1
  },
  {
    no: 3,
    date: "7 September 2024",
    headline: "Indonesia Youth Sustainability Forum 2024: Wadah Generasi Muda Diskusi terkait Isu Sosial-Lingkungan",
    type: "Televisi",
    media: "TV One",
    url: "https://www.youtube.com/watch? v=m_ECBswiOLE",
    tone: "Positif",
    tier: 1
  },
  {
    no: 4,
    date: "8 September 2024",
    headline: "Menpora Apresiasi Indonesia Youth Sustainability Forum 2024",
    type: "Digital",
    media: "Sinpo TV",
    url: "https://www.youtube.com/watch? v=gzDcR8yKjrQ",
    tone: "Positif",
    tier: 2
  },
  {
    no: 5,
    date: "8 September 2024",
    headline: "Inspiratif! IYSF 2024 Dorong Aksi Nyata Anak Muda untuk Masa Depan Berkelanjutan, Anindya Bakrie: Peluang Indonesia jadi Pusat Dekarbonisasi",
    type: "Online",
    media: "TV One News",
    url: "https://www.tvonenews. com/ekonomi/243977-inspiratif-iysf-2024- dorong-aksi-nyata-anak-muda-untuk-masa- depan-berkelanjutan-anindya-bakrie- peluang-indonesia-jadi-pusat-dekarbonisasi",
    tone: "Positif",
    tier: 1
  },
  {
    no: 6,
    date: "8 September 2024",
    headline: "Anindya Bakrie Optimis Anak Muda Berperan Besar Dorong Ekonomi RI Keberlanjutan",
    type: "Online",
    media: "viva.co.id",
    url: "https://www.viva.co.id/bisnis/1750282- anindya-bakrie-optimis-anak-muda- berperan-besar-dorong-ekonomi-ri- keberlanjutan?page=2",
    tone: "Positif",
    tier: 1
  },
  {
    no: 7,
    date: "8 September 2024",
    headline: "Menpora Apresiasi Indonesia Youth Sustainability Forum 2024",
    type: "Online",
    media: "rri.co.id",
    url: "https://www.rri.co. id/daerah/960029/menpora-dito-apresiasi- penyelenggaraan-indonesia-youth- sustainability-forum",
    tone: "Positif",
    tier: 1
  },
  {
    no: 8,
    date: "8 September 2024",
    headline: "Pentingnya Pemahaman Isu Pembangunan Berkelanjutan, Perubahan Iklim, Energi Bersih",
    type: "Online",
    media: "rri.co.id",
    url: "https://www.rri.co. id/nasional/960585/pentingnya- pemahaman-isu-pembangunan- berkelanjutan-perubahan-iklim-energi- bersih",
    tone: "Positif",
    tier: 1
  },
  {
    no: 9,
    date: "8 September 2024",
    headline: "Dihadiri 8 ribu peserta, Menpora Dito Apresiasi Gelara IYSF 2024",
    type: "Online",
    media: "beritabuana.co",
    url: "https://beritabuana.co/2024/09/08/dihadiri- 8-ribu-peserta-menpora-dito-apresiasi- gelaran-iysf-2024/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 10,
    date: "8 September 2024",
    headline: "Dihadiri 8 ribu peserta, Menpora Dito Apresiasi Gelara IYSF 2025",
    type: "Online",
    media: "www.pojokbaca. id",
    url: "https://www.pojokbaca. id/berita/225067234/dihadiri-8-ribu-peserta- menpora-apresiasi-indonesia-youth- sustainability-forum-2024",
    tone: "Positif",
    tier: 2
  },
  {
    no: 11,
    date: "8 September 2024",
    headline: "Dihadiri 8 ribu peserta, Menpora Dito Apresiasi Gelara IYSF 2026",
    type: "Online",
    media: "realitarakyat.com",
    url: "https://realitarakyat.com/2024/09/dihadiri-8- ribu-peserta-menpora-apresiasi-indonesia- youth-sustainability-forum-2024/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 12,
    date: "8 September 2024",
    headline: "Menpora Apresiasi Indonesia Youth Sustainability Forum 2024",
    type: "Online",
    media: "waspada.id",
    url: "https://www.waspada. id/nusantara/menpora-apresiasi-indonesia- youth-sustainability-forum-2024/",
    tone: "Positif",
    tier: 1
  },
  {
    no: 13,
    date: "8 September 2024",
    headline: "Menpora Apresiasi Indonesia Youth Sustainability Forum 2024",
    type: "Online",
    media: "jurnas.com",
    url: "https://www.jurnas. com/mobile/artikel/161285/Menpora- Apresiasi-Kegiatan-Indonesia-Youth- Sustainability-Forum-2024/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 14,
    date: "8 September 2024",
    headline: "Menpora Apresiasi Indonesia Youth Sustainability Forum 2024",
    type: "Online",
    media: "njpdaily.com",
    url: "https://njpdaily.com/2024/09/08/menpora- apresiasi-indonesia-youth-sustainability- forum-2024/",
    tone: "Positif",
    tier: 1
  },
  {
    no: 15,
    date: "8 September 2024",
    headline: "Menpora Apresiasi Indonesia Youth Sustainability Forum 2024",
    type: "Online",
    media: "elshinta.com",
    url: "https://elshinta. com/news/347416/2024/09/08/menpora- apresiasi-indonesia-youth-sustainability- forum-2024",
    tone: "Positif",
    tier: 1
  },
  {
    no: 16,
    date: "8 September 2024",
    headline: "Dihadiri 8 ribu peserta, Menpora Dito Apresiasi Gelara IYSF 2026",
    type: "Online",
    media: "harianterbit.com",
    url: "https://www.harianterbit. com/olahraga/27413503849/sebanyak-8- ribu-peserta-hadiri-indonesia-youth- sustainability-forum-2024",
    tone: "Positif",
    tier: 1
  },
  {
    no: 17,
    date: "8 September 2024",
    headline: "Menpora Apresiasi Indonesia Youth Sustainability Forum 2024",
    type: "Online",
    media: "parle.co.id",
    url: "https://parle.co.id/2024/09/08/apresiasi- gelaran-iysf-2024-menpora-pemuda-harus- peduli-soal-isu-keberlanjutan/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 18,
    date: "8 September 2024",
    headline: "Menpora Apresiasi Indonesia Youth Sustainability Forum 2024",
    type: "Online",
    media: "p utraindonews. com",
    url: "https://putraindonews. com/nusantara/apresiasi-gelaran-iysf-2024- menpora-pemuda-harus-peduli-soal-isu- keberlanjutan/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 19,
    date: "8 September 2024",
    headline: "Menpora Apresiasi Indonesia Youth Sustainability Forum 2024",
    type: "Online",
    media: "analisnews.co.id",
    url: "https://analisnews.co.id/arsip/67180/hadir- di-iysf-2024-menpora-optimis-isu- keberlanjutan-lahirkan-inovasi/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 20,
    date: "8 September 2024",
    headline: "Transisi Energi, Ekonomi Biru Warnai Gelaran IYSF 2024",
    type: "Online",
    media: "bandungpunyab erita.com",
    url: "https://bandungpunyaberita.com/transisi- energi-ekonomi-biru-warnai-gelaran-iysf- 2024/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 21,
    date: "8 September 2024",
    headline: "Transisi Energi, Ekonomi Biru Warnai Gelaran IYSF 2024",
    type: "Online",
    media: "pakuanpos.com",
    url: "https://www.pakuanpos. com/2024/09/transisi-energi-ekonomi-biru- warnai.html",
    tone: "Positif",
    tier: 1
  },
  {
    no: 22,
    date: "8 September 2024",
    headline: "BCF Gelar IYSF 2024",
    type: "Online",
    media: "tintahukum.com",
    url: "https://www.tintahukum.com/2024/09/bcf- gelar-iysf-2024-iklim-dan.html",
    tone: "Positif",
    tier: 2
  },
  {
    no: 23,
    date: "8 September 2024",
    headline: "Menpora Optimis Isu Keberlanjutan Lahirkan Inovasi",
    type: "Online",
    media: "bewaranusantar a.com",
    url: "https://bewaranusantara. com/2024/09/09/hadir-di-iysf-2024- menpora-optimis-isu-keberlanjutan-lahirkan- inovasi/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 24,
    date: "8 September 2024",
    headline: "IYSF 2024 Dorong Aksi Anak Muda",
    type: "Online",
    media: "akuratnews.id",
    url: "https://www.akuratnews.id/2024/09/iysf- 2024-dorong-aksi-anak-muda-hidup.html",
    tone: "Positif",
    tier: 1
  },
  {
    no: 25,
    date: "8 September 2024",
    headline: "IYSF 2024 Angkat Isu Iklim dan Lingkungan",
    type: "Online",
    media: "industriindonesia\n .id",
    url: "https://industriindonesia.id/bcf-gelar-iysf- 2024-angkat-isu-iklim-dan-lingkungan/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 26,
    date: "11 September 2024",
    headline: "Buku untuk Anak Muda Memahami Perubahan Iklim",
    type: "Online",
    media: "viva.co.id",
    url: "https://www.viva.co.id/gaya-hidup/inspirasi- unik/1751012-buku-ini-hadir-untuk- generasi-muda-memahami-perubahan-iklim",
    tone: "Positif",
    tier: 1
  },
  {
    no: 27,
    date: "11 September 2024",
    headline: "Habibie Center & SRE Luncurkan Buku Climate Change",
    type: "Online",
    media: "akuratnews.id",
    url: "https://www.akuratnews.id/2024/09/habibie- centre-dan-sre-luncurkan.html",
    tone: "Positif",
    tier: 1
  },
  {
    no: 28,
    date: "11 September 2024",
    headline: "Panduan untuk Anak Muda untuk Memahami Perubahan Iklim",
    type: "Online",
    media: "industriindonesia\n .id",
    url: "https://industriindonesia.id/buku-panduan- utama-generasi-muda-indonesia-untuk- memahami-perubahan-iklim-diluncurkan/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 29,
    date: "11 September 2024",
    headline: "Memahami Perubahan Iklim Lewat Buku Climate Action 101",
    type: "Online",
    media: "sketsindonews. com",
    url: "https://sketsindonews.com/memahami- perubahan-iklim-lewat-buku-climate-action- 101-indonesias-guide-for-newbies/",
    tone: "Positif",
    tier: 2
  },
  {
    no: 30,
    date: "11 September 2024",
    headline: "Generasi Muda Juga Harus Paham Isu Ketahanan Energi",
    type: "Online",
    media: "wartakota. tribunnews.com",
    url: "https://wartakota.tribunnews. com/2024/09/11/dukung-pemerintahan- prabowo-gibran-yec-bikin-komunitas-energi- dan-lingkungan-anak-muda-indonesia",
    tone: "Positif",
    tier: 1
  },
  {
    no: 31,
    date: "11 September 2024",
    headline: "Generasi Muda Juga Harus Paham Isu Ketahanan Energi",
    type: "Online",
    media: "metrotvnews. com",
    url: "https://www.metrotvnews. com/read/NLMC27ry-generasi-muda-juga- harus-paham-isu-ketahanan-energi",
    tone: "Positif",
    tier: 1
  },
  {
    no: 1,
    date: "7 September 2024",
    headline: "Live Streaming YouTube | Indonesia Youth Sustainability Forum 2024",
    type: "YouTube",
    media: "Bakrie Center Foundation",
    url: "https://www.youtube.com/watch?v=SCIZzCD0QmI&t=3954s",
    tone: "",
    tier: ""
  },
  {
    no: 2,
    date: "8 September 2024",
    headline: "[LIVE] Indonesia Youth Sustainability Forum 2024 | tvOne",
    type: "YouTube",
    media: "tvOne",
    url: "https://www.youtube.com/watch?v=5B0cOLV3Xvs&t=3748s",
    tone: "",
    tier: ""
  },
  {
    no: 3,
    date: "27 September 2024",
    headline: "Youth Green Initiatives at the Indonesia Youth Sustainability Forum 2024",
    type: "YouTube",
    media: "Bakrie Center Foundation",
    url: "https://www.youtube.com/watch?v=HuTx-iVlUBw",
    tone: "",
    tier: ""
  },
  {
    no: 4,
    date: "27 Agustus 2024",
    headline: "Poster Youth Voices for Better and Sustainable Living Speech Competition",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C-xtSWzvLdv/",
    tone: "",
    tier: ""
  },
  {
    no: 5,
    date: "21 Agustus 2024",
    headline: "Community Partner with FPCI Indo. Poster IYSF",
    type: "Instagram",
    media: "@bakriecenter @fpciindo",
    url: "https://www.instagram.com/p/C-6qL9xvQW-/",
    tone: "",
    tier: ""
  },
  {
    no: 6,
    date: "4 September 2024",
    headline: "Kita Semua Harus Melek Transisi Energi!",
    type: "Instagram",
    media: "@bakricenter @zonaebt",
    url: "https://www.instagram.com/p/C_e9oaFzFWs/?img_index=1",
    tone: "",
    tier: ""
  },
  {
    no: 7,
    date: "8 September 2024",
    headline: "Menyemangati anak-anak muda yang hadir di acara Indonesia Youth Sustainability Forum 2024 @iysf.id",
    type: "Instagram",
    media: "@anindyabakrie",
    url: "https://www.instagram.com/p/C_ph9slOYy_/",
    tone: "",
    tier: ""
  },
  {
    no: 8,
    date: "12 Agustus 2024",
    headline: "Apa Iya Transisi Energi Sepenting Itu?",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C-kjBtQvNA9/?img_index=4",
    tone: "",
    tier: ""
  },
  {
    no: 9,
    date: "13 Agustus 2024",
    headline: "Kenali Ekonomi Biru, Pengelolaan Sumber Daya Laut Berkelanjutan!",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C-mszDsPQ83/?img_index=3",
    tone: "",
    tier: ""
  },
  {
    no: 10,
    date: "20 Agustus 2024",
    headline: "Unlocking Blue Economy Benefits for A Sustainable Future",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C-mtCaZvC62/?img_index=1 ",
    tone: "",
    tier: ""
  },
  {
    no: 11,
    date: "20 Agustus 2024",
    headline: "Unloking The Young Generation's Potential for Sustainable Development ",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C-4vGqZPfan/?img_index=1",
    tone: "",
    tier: ""
  },
  {
    no: 12,
    date: "20 Agustus 2024",
    headline: "What is The Conection Between Our Culture and The Concept of Sustainability",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C-4vpGVvHhA/?img_index=1",
    tone: "",
    tier: ""
  },
  {
    no: 13,
    date: "20 Agustus 2024",
    headline: "The Origin of The Balinese Subak System ",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C-4wjQGPRpW/?img_index=1",
    tone: "",
    tier: ""
  },
  {
    no: 14,
    date: "20 Agustus 2024",
    headline: "A Pathway to a Healthier Planet Sustainable Living",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C-4w5x3PM0p/?img_index=1",
    tone: "",
    tier: ""
  },
  {
    no: 15,
    date: "20 Agustus 2024",
    headline: "The benefits of practicing the Sustainable Living ",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C-4xARQvn3J/?img_index=1",
    tone: "",
    tier: ""
  },
  {
    no: 16,
    date: "11 September 2024",
    headline: "The full recap of Indonesia Youth Sustainability Forum",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C_xhTnKuvOh/",
    tone: "",
    tier: ""
  },
  {
    no: 17,
    date: "12 September 2024",
    headline: "Indonesia Youth Sustainability Forum 2024 - Recap of the Panel Discussion \"Energy Transition",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C_0CgEruEdx/",
    tone: "",
    tier: ""
  },
  {
    no: 18,
    date: "12 September 2024",
    headline: "Indonesia Youth Sustainability Forum 2024 - Recap of the Panel Discussion \"Blue Economy\"",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C_0JvJ9uLcD/",
    tone: "",
    tier: ""
  },
  {
    no: 19,
    date: "12 September 2024",
    headline: " Jejakin berpartisipasi dalam Indonesia Youth Sustainability Forum",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C_0N9QKTSuN/?img_index=1",
    tone: "",
    tier: ""
  },
  {
    no: 20,
    date: "13 September 2024",
    headline: "Indonesia Youth Sustainability Forum 2024 - Recap of the Panel Discussion \"Human Capital, Technology, and Innovation\"",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C_1voYSuGB7/  ",
    tone: "",
    tier: ""
  },
  {
    no: 21,
    date: "13 September 2024",
    headline: "Indonesia Youth Sustainability Forum 2024 - Recap of the Panel Discussion \"History, Culture, and Heritage\"",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C_1xF86OG5b/",
    tone: "",
    tier: ""
  },
  {
    no: 22,
    date: "13 September 2024",
    headline: "Indonesia Youth Sustainability Forum 2024 - Recap of the Panel Discussion \"Sustainable Living\"",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/C_1xqEpuKHB/",
    tone: "",
    tier: ""
  },
  {
    no: 23,
    date: "20 September 2024",
    headline: "Energy Transition ",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/DAJET1POzSC/",
    tone: "",
    tier: ""
  },
  {
    no: 24,
    date: "22 September 2024",
    headline: "Blue Economy",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/DAOgb0CurVf/",
    tone: "",
    tier: ""
  },
  {
    no: 25,
    date: "24 September 2024",
    headline: "Human Capital, Technology, and Innovation",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/DATU7UROzyO/",
    tone: "",
    tier: ""
  },
  {
    no: 26,
    date: "25 September 2024",
    headline: "History, Culture, and Heritage",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/DAV-ApkuGsH/",
    tone: "",
    tier: ""
  },
  {
    no: 27,
    date: "28 September 2024",
    headline: "Sustainable Living",
    type: "Instagram",
    media: "@iysf.id",
    url: "https://www.instagram.com/p/DAdqloPOS4s/",
    tone: "",
    tier: ""
  }
]

module.exports = media;
