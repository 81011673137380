import React, { useEffect } from 'react';
import Masonry from 'masonry-layout';
import gallery from '../utils/Data/gallery';

const Gallery = () => {
  useEffect(() => {
    const elem = document.querySelector('.row');
    new Masonry(elem, {
      percentPosition: true,
    });
  }, []);

  return (
    <div className='container mt-5'>
      <div className='row'>
        {gallery.map((value, key) => (
          <div key={key} className="col-sm-6 col-lg-4 mb-4">
            <div className="card">
              <img className="object-fit-cover card-img-top" src={value.image} alt='Images' height={key % 2 === 0 ? 200 : 500}></img>
            </div>
          </div>
        ))}
      </div>
    </div >
  )
}

export default Gallery