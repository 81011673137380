import React, { useState } from 'react';
import media from '../utils/Data/media';

const Media = () => {
  const [all, setAll] = useState(true);
  const [type, setType] = useState("YouTube");

  const onChangeTypeHandler = (type) => {
    setAll(false);
    setType(type)
  }

  const style = {
    cursor: 'pointer',
  }

  return (
    <div className='container mt-5'>
      <h1 className='text-center pb-3'><strong>MEDIA</strong></h1>

      <div class="hstack gap-3 mb-3">
        <span onClick={() => setAll(true)} style={style} class="p-2 badge rounded-pill text-bg-success">All</span>
        <span onClick={() => onChangeTypeHandler("Online")} style={style} class="p-2 badge rounded-pill text-bg-success">Online</span>
        <span onClick={() => onChangeTypeHandler("Televisi")} style={style} class="p-2 badge rounded-pill text-bg-success">Televisi</span>
        <span onClick={() => onChangeTypeHandler("Instagram")} style={style} class="p-2 badge rounded-pill text-bg-success">Instagram</span>
        <span onClick={() => onChangeTypeHandler("YouTube")} style={style} class="p-2 badge rounded-pill text-bg-success">Youtube</span>
      </div>

      <div className="row">
        {media.filter(all === true ? () => true : value => value.type === type).map((value, key) => (
          <div key={key} className='col-sm-6 col-lg-4 mb-4'>
            <div className="card">
              <div className="card-body">
                <a href={value.url} target='_blank' className='card-title h5' rel="noreferrer">{value.headline}</a>
                <p className='card-text'>{value.type}@{value.media}</p>
                <small>{value.date}</small>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Media;
