const path = 'https://firebasestorage.googleapis.com/v0/b/iysf-web.appspot.com/o/speakers';
const altMedia = '?alt=media';

const locationKeynote = `${path}%2Fkeynote-speakers%2F`;
const locationHistory = `${path}%2Fhistory-culture-and-heritage%2F`;
const locationEnergy = `${path}%2Fenergy-transition%2F`;
const locationBlue = `${path}%2Fblue-economy%2F`;
const locationHumanCapital = `${path}%2Fhuman-capital-technology-and-innovation%2F`;
const locationSustainableLiving = `${path}%2Fsustainable-living%2F`;
const locationClosingSpeech = './images/Speakers/ClosingSpeech/';

const ourSpeakers = [
  {
    session: "Keynote Speakers",
    speakers: [
      {
        id: 1,
        name: 'Chelsea Islan',
        title: 'ISF Advocate',
        company: '',
        description: '',
        image: `${locationKeynote}Chelsea Islan_Actress, UNDP SDGs Mover, ISF Advocate.jpg?alt=media`,
        socmed: {
          instagram: 'https://www.instagram.com/chelseaislan',
        }
      },
      {
        id: 2,
        name: 'Anindya Novyan Bakrie',
        title: 'Founder',
        company: 'Bakrie Center Foundation',
        description: '',
        image: `${locationKeynote}Anindya N. Bakrie_Founder & Chairman, Bakrie Center Foundation.jpg?alt=media`,
        socmed: {
          instagram: 'https://www.instagram.com/anindyabakrie',
        }
      },
      {
        id: 3,
        name: 'Ario Bimo Nandito Ariotedjo',
        title: 'Minister of',
        company: 'Youth and Sports Affairs of the Republic of Indonesia',
        description: '',
        image: `${locationKeynote}Ario Bimo Nandito Ariotedjo_Menteri Pemuda dan Olahraga.jpg?alt=media`,
        socmed: {
          instagram: 'https://www.instagram.com/ditoariotedjo',
        }
      },
      {
        id: 4,
        name: 'Rachmat Kaimuddin',
        title: 'Deputi Bidang Koordinasi Infrastruktur & Transportasi',
        company: 'Kementerian Koordinasi',
        description: '',
        image: `${locationKeynote}Rachmat Kaimuddin_Deputi Bidang Koordinasi Infrastruktur & Transportasi, Kemenkomarves.jpg?alt=media`,
        socmed: {
          instagram: 'https://www.instagram.com/rachmatkaimuddin',
        }
      },
      {
        id: 5,
        name: 'Rahmad Pribadi',
        title: 'Director',
        company: 'PT Pupuk Indonesia',
        description: '',
        image: `${locationKeynote}Rahmad Pribadi_President Director, PT Pupuk Indonesia (Persero).jpg?alt=media`,
        socmed: {
          instagram: 'https://www.instagram.com/rahmadpri',
        }
      },
    ],
  },
  {
    session: "History, Cultural, and Heritage",
    speakers: [
      {
        id: 1,
        name: 'Kevindra Soemantri',
        title: 'Culinary Author',
        company: '',
        description: 'Kevindra Prianto Soemantri, also known as Kevindra, is a food writer and culinary observer from and based in Indonesia. He has contributed numerous writings about food and restaurants to publications such as The Jakarta Post and Esquire',
        image: `${locationHistory}Kevindra Soemantri_Culinary Author.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/kevindrasoemantri',
        }
      },
      {
        id: 2,
        name: 'Era Soekamto',
        title: 'Founder',
        company: 'Nusantara Wisdom',
        description: "Batik, Royal Java, and Nusantara wisdom are integral to Era Soekamto's journey as a fashion and batik designer, as well as a cultural enthusiast. With 26 years of experience in fashion design and cultural arts, Era has deeply explored Indonesia's historical and cultural richness. Her passion lies in serving a higher purpose through fashion, batik creations, and education on an international scale. For her, creating is a sacred service, a way to convey profound messages to the modern world. By promoting sacred art, she contributes to building civilization and fostering peace. Through her designs, Era seeks to enhance individuals' appearances, boost their confidence, and help them express their best selves. Beyond fashion, she believes that divine art has the power to touch hearts, awaken awareness, and strengthen the relationship between humanity and the divine, ultimately fostering a sustainable and harmonious environment.",
        image: `${locationHistory}Era Soekamto_Fashion Designer, Batik Designer, Nusantara Wisdom Consultant.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/erasoekamto',
        }
      },
      {
        id: 3,
        name: 'Gita Wirjawan',
        title: 'Educator & Entrepreneur',
        company: 'Ancora Group',
        description: 'Gita Wirjawan is an Indonesian investment banker, entrepreneur, philanthropist, musician, and podcaster. He previously served as the Minister of Trade of the Republic of Indonesia during President Susilo Bambang Yudhoyono s Second United Indonesia Cabinet. He is the founder of Ancora Group and Ancora Foundation. He also hosts a podcast called "Endgame," where he invites notable speakers who can inspire many people, including Indonesian youth',
        image: `${locationHistory}Gita Wirjawan_Educator, Entrepreneur, and Host of Endgame Podcast.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/gwirjawan',
        }
      },
    ],
  },
  {
    session: "Energy Transition",
    speakers: [
      {
        id: 1,
        name: 'Nadia Habibie',
        description: "Executive Board at The Habibie Center, where she leads key sustainability initiatives. She previously worked in the Coordinating Ministry of Maritime Affairs and Investments, focusing on introducing passenger electric vehicle incentives to boost adoption in Indonesia. Nadia helped manage the Indonesia Sustainability Forum last year, which brought together global leaders like the World Bank President and the IMF Managing Director. Before her government role, she was Chief of Staff to the COO at Xendit, a fintech unicorn. Nadia holds a Bachelor's degree in Economics from the University of Chicago and a Master's in Finance from Imperial College Business School.",
        title: 'Executive Board Secretary',
        company: 'The Habibie Center',
        image: `${locationEnergy}Nadia Habibie_Executive Board Secretary, The Habibie Center.jpg${altMedia}`,
        socmed: {
          instagram: null,
          linkedin: 'https://www.linkedin.com/in/nadia-habibie-52b5a074'
        }
      },
      {
        id: 2,
        name: 'Ronald Sinaga',
        description: '',
        title: 'CEO',
        company: 'Helio Synar',
        image: `${locationEnergy}Ronald Sinaga_CEO, Helio Synar.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/brorondm',
        }
      },
      {
        id: 3,
        name: 'Fadli Rahman',
        description: '',
        title: 'Chairman',
        company: 'Youth Energy Council',
        image: `${locationEnergy}Fadli Rahman_Chairman, Youth Energy Council.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/fadli.rahman.phd',
        }
      },
      {
        id: 4,
        name: 'Aufar Satria',
        description: 'Aufar Satria is a postgraduate student at Harvard University, bringing a wealth of experience from management consulting, government, and the private sector. As a commissioner of the Society of Renewable Energy (SRE) Indonesia, Aufar plays a pivotal role in advancing sustainable energy initiatives. He holds an MBA from the University of Cambridge and a B.Sc. from the Bandung Institute of Technology (ITB).',
        title: 'Commissioner',
        company: 'Society of Renewable Energy Indonesia',
        experience: [
          {
            title: 'Co-founder & Director',
            company: 'Akartha Energy',
          }
        ],
        image: `${locationEnergy}Aufar Satria_Co-founder & Director, Akartha Energy Commissioner, SRE Indonesia.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/aufarsatria',
        }
      },
    ],
  },
  {
    session: "Blue Economy",
    speakers: [
      {
        id: 1,
        name: 'Bimo Soewadji',
        description: 'Bimo is a sustainability enthusiast with a background in geophysical engineering, a well-rounded commercial track record in the FMCG sector, and ESG investing experience in topics such as Regenerative Agriculture, Watershed Protection, Recycling, and Milk Sourcing. At CarbonEthics, Bimo is the Co-founder and Chief Executive Officer with the mission to restore climate balance, improve the livelihood of farmers, and create green jobs for young people.',
        title: 'Co-Founder & CEO',
        company: 'CarbonEthics',
        image: `${locationBlue}Bimo Soewadji_Co-founder & CEO, CarbonEthics.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/bimosoewadji',
        }
      },
      {
        id: 2,
        name: 'Janis Argeswara',
        description: "Janis is a Country Manager and Scientist at the Marine Megafauna Foundation (MMF) in Indonesia. Her research primarily focuses on the biological and ecological aspects of Indonesia's manta rays and sea turtles. Additionally, she explores interdisciplinary socio-economic issues, such as the effects of tourism and marine pollution on marine megafauna. Janis earned her Bachelor's degree in Marine Science from Udayana University in 2019 and recently completed her Master’s degree in Biology at Florida International University. She is currently based in Bali, Indonesia.",
        title: 'Project Leader',
        company: 'Marine Megafauna Foundation',
        image: `${locationBlue}Janis Argeswara_Project Leader, Marine Megafauna Foundation.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/janisargeswara',
        }
      },
      {
        id: 3,
        name: 'Bustar Maitar',
        description: "Bustar Maitar is the founder and CEO of EcoNusa Foundation, a local non-profit organization dedicated to environmental and socio-economic initiatives in Eastern Indonesia. EcoNusa has led transformative initiatives, including the revocation of palm oil concessions covering an area 2.5 times the size of Los Angeles and the mapping of nearly half a million hectares of customary forests in Papua, one of the world’s most biodiverse regions. In 2022, Bustar founded KOBUMI Indonesia Enterprise with a mission to foster sustainable local economies through commodities trading, support indigenous-led eco-tourism, and develop nature-based MSMEs in Papua and Maluku. Before founding EcoNusa in 2017, Bustar served as a global campaigner for Greenpeace for 11 years, where his impactful work contributed to the 2011 Indonesian government’s moratorium on forest conversion. His efforts have influenced global supply chains, resulting in 75% of the world’s palm oil and 80% of Indonesia’s pulp and paper production becoming deforestation-free.",
        title: 'Founder and CEO',
        company: 'Econusa Foundation',
        image: `${locationBlue}Bustar Maitar_CEO, EcoNusa Foundation.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/pacebustar',
        }
      },
    ],
  },
  {
    session: "Human Capital, Technology and Innovation",
    speakers: [
      {
        id: 1,
        name: 'Tommy Tjiptadjaja',
        description: 'Tommy, a social entrepreneur, is dedicated to creating sustainable value by integrating positive social and environmental impacts into products and services. Recognized as a Social Entrepreneur by the Schwab Foundation of the World Economic Forum and a Harvard University scholarship recipient, he was named to Asia Tatler’s Impact List 2020 and received the Real Leaders Eco-Innovation Award in 2022. Tommy co-founded Greenhope, a bioplastic company that uses natural minerals and locally sourced tapioca to develop biodegradable resins, reducing microplastic contamination. The company supports local cassava farmers by purchasing materials at prices over 25% above market rates, aligning with SDG goals on poverty, decent work, and inequality. Since 2017, Greenhope has partnered with over 300 top brands worldwide, making over 125,000 tons of plastic applications sustainable in more than 15 countries',
        title: 'Co-founder & CEO',
        company: 'Greenhope',
        image: `${locationHumanCapital}Tommy Tjiptadjaja_Co-Founder & CEO, Greenhope.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/tommytjiptadjaja',
        }
      },
      {
        id: 2,
        name: 'Utari Octavianty',
        description: "Utari Octavianty is the Co-Founder and Chief Sustainability Officer at Aruna, where she leads corporate affairs and sustainability initiatives, including strategic communications, government relations, and ESG implementation. Coming from a fishing family, Utari recognized the industry's challenges and co-founded Aruna to increase transparency by connecting fishers directly to local and global markets through a digital platform. Under her leadership, Aruna collaborates with over 55,000 fishermen across 150+ coastal communities in Indonesia, exporting seafood to more than eight countries. Her achievements include being named in Forbes 30 Under 30 Asia and the Top 100 Asia-Pacific Women-Powered Businesses by JP Morgan. Since its founding in 2016, Aruna has exported over 39,000 tons of seafood, transforming Indonesia's fishing industry.",
        title: 'CSO',
        company: 'Aruna',
        image: `${locationHumanCapital}Utari Octavianty_CSO, Aruna.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/utarioctavianty',
        }
      },
      {
        id: 3,
        name: 'Andhika Sudarman',
        description: "Andhika Sudarman is the CEO of Dealls, a rapidly growing platform that has impacted the job-seeking and mentoring landscape in Indonesia since 2020. As a graduate of Universitas Indonesia and Harvard Law School, he made history as the first Indonesian to speak at Harvard Law’s commencement. Andhika Sudarman has a broad career spanning technology, law, and social impact. His commitment to excellence is evident in every endeavor, driving Dealls to grow from zero to over two million users in just 10 months, as well as winning Indonesia's Best App of 2021 by Google Play in the first 14 months. His innovation makes him a leading voice at the intersection of technology and human capital development.",
        title: 'CEO',
        company: 'Dealls',
        image: `${locationHumanCapital}Andhika Sudarman_CEO, Dealls.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/andhikasudarman_',
        }
      },
    ],
  },
  {
    session: "Sustainable Living",
    speakers: [
      {
        id: 1,
        name: 'Bukhi Prima',
        description: '',
        title: 'Sustainable Living Practitioner',
        company: '',
        image: `${locationSustainableLiving}Bukhi Prima_Sustainable Living Observer & Practitioner.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/holisticbukhi',
        }
      },
      {
        id: 2,
        name: 'Intan Anggita Pratiwie',
        description: "Intan Anggita Pratiwie is a mompreneur and a pioneer in sustainable fashion, recycling art, and subculture. Since 2004, she has been a prominent figure in the sustainable fashion movement, starting her journey with the fashion brand “Sight from The East” which blends denim with Indonesian ikat weaving. Her innovative approach earned her the Kartini Next Generation Award in 2014 and recognition as a young Agent of Change by UNFPA in 2015. In 2019, she co-founded Setali Indonesia focused on research and development, and her recent work includes textile art for Greenpeace Southeast Asia and being a nominee for Berita Satu People & Inspiration Awards 2022. Intan has also been recognized as Women of The Year by Her World Indonesia and has participated in several international exhibitions and artist residencies, including in Japan and Thailand.",
        title: 'Co-founder',
        company: 'Setali Indonesia',
        image: `${locationSustainableLiving}Intan Anggita_Co-founder, Setali Indonesia.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/intananggitapratiwie',
        }
      },
      {
        id: 3,
        name: 'Soraya Cassandra',
        description: 'Rara Sekar Larasati, aka hara, is an Indonesian vocalist, gardener, researcher, and cultural anthropologist. A powerful voice with a prolific academic background, Rara campaigns for food sovereignty through performances in and about nature, incorporating local food, farms, foraging, and biodiversity into her music. Rara’s solo project, hara, blends inspiration from folk with Indonesian traditional music, ambient and field recording with a focus on exploring themes of ecology, climate crisis, tradition and rituals, life, grief and collective care. Her work communicates critical environmental and social issues and is steeped in a deep research practice that honors traditional Javanese rituals and songs. Rara actively engages her audience in through community practices during her tours which incorporate wild food foraging, community garden to table cooking and community potlucks.',
        title: 'Co-Founder & CEO',
        company: 'Kebun Kumara',
        image: `${locationSustainableLiving}Soraya Cassandra_Co-Founder & CEO, Kebun Kumara.jpg${altMedia}`,
        socmed: {
          instagram: 'https://www.instagram.com/sorayacassandra',
        }
      },
    ],
  },
  {
    session: "Closing Speech and Performance",
    speakers: [
      {
        id: 1,
        name: 'Ganis Bustami',
        description: '',
        title: 'Corporate Secretary Manager',
        company: 'PT Sarana Multi Infrastruktur (Persero)',
        image: `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png`,
        socmed: {
          instagram: null,
          linkedin: 'https://www.linkedin.com/in/ganis-syahputra-bustami-259001a8',
        }
      },
      {
        id: 2,
        name: 'Jimmy Rifai Gani',
        description: '',
        title: 'CEO',
        company: 'Bakrie Center Foundation',
        image: `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png`,
        socmed: {
          instagram: 'https://www.instagram.com/jimmyrgani',
        }
      },
    ],
  },
]

module.exports = ourSpeakers;
