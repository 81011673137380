import './App.css';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// Pages
import Landing from './Pages/Landing';
// Components
import NavigationBCF from './Components/NavigationBCF';
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';

// Main Pages
import Speakers from './Pages/Speakers';
import Partners from './Pages/Partners';
import Gallery from './Pages/Gallery';
import Media from './Pages/Media';
import NotFound from './Pages/NotFound';

import { db, analytics } from './utils/firebase-config';
import { logEvent } from 'firebase/analytics';

// Scan
import Register from './Pages/Scan/Register';
import Meals from './Pages/Scan/Meals';
import Redirect from './Pages/Redirect';
import RegisterDashboard from './Pages/Scan/Dashboard/Register';
import MealsDashboard from './Pages/Scan/Dashboard/Meals';

function App() {
  useEffect(() => {
    logEvent(analytics, 'notification_received');
  }, []);

  return (
    <>
      <header>
        <Navigation />
      </header>
      <main>
        <Routes>
          <Route index element={<Landing />} />
          <Route path='speakers' element={<Speakers />} />
          <Route path='partners' element={<Partners />} />
          <Route path='gallery' element={<Gallery />} />
          <Route path='media' element={<Media />} />
          {/* <Route path='scan'>
            <Route path='dashboard'>
              <Route path='meals' element={<MealsDashboard />} />
              <Route path='register' element={<RegisterDashboard />} />
            </Route>
            <Route path='register' element={<Register />} />
            <Route path='meals' element={<Meals />} />
          </Route> */}
          <Route path='feedback' element={<Redirect />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </main>
      <footer>
        <Footer db={db} />
      </footer>
    </>
  );
}

// function Redirect() {
//   window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSeqKcJ5qQ2DC9OYF7NMdj1hhvDce44W5PYPcI_wdr_xMZgAhQ/viewform')
// }

export default App;
