
const mainUrl = 'https://firebasestorage.googleapis.com/v0/b/iysf-web.appspot.com/o/gallery';
const altMedia = '?alt=media';

const locationOpening = `${mainUrl}%2Fopening%2F`;
const locationBlueEconomy = `${mainUrl}%2Fblue-economy%2F`;
const locationEnergyTransition = `${mainUrl}%2Fenergy-transition%2F`;
const locationHistoryCultureandHeritage = `${mainUrl}%2Fhistory-culture-and-heritage%2F`;
const locationHumanCapitalTechnologyInnovation = `${mainUrl}%2Fhuman-capital-technology-and-innovation%2F`
const locationSustainableliving = `${mainUrl}%2Fsustainable-living%2F`

const locationPenonton = `${mainUrl}%2Fpenonton%2F`;
const locationWallofframe = `${mainUrl}%2Fwall-of-frame%2F`;
const locationIntermezo = `${mainUrl}%2Fintermezo-sesi%2F`;
const locationBooth = `${mainUrl}%2Fbooth%2F`;
const locationClosing = `${mainUrl}%2Fclosing%2F`;

const galleryAll = [
  {
    id: 1,
    image: `${locationOpening}3.jpg${altMedia}`
  },
  {
    id: 2,
    image: `${locationOpening}4.jpg${altMedia}`
  },
  {
    id: 3,
    image: `${locationOpening}5.jpg${altMedia}`
  },
  {
    id: 4,
    image: `${locationOpening}6.jpg${altMedia}`
  },
  {
    id: 5,
    image: `${locationOpening}7.jpg${altMedia}`
  },
  {
    id: 6,
    image: `${locationOpening}8.jpg${altMedia}`
  },
  {
    id: 7,
    image: `${locationOpening}9.jpg${altMedia}`
  },
  {
    id: 8,
    image: `${locationOpening}10.jpg${altMedia}`
  },
  {
    id: 9,
    image: `${locationBlueEconomy}16.jpg${altMedia}`
  },
  {
    id: 10,
    image: `${locationBlueEconomy}17.jpg${altMedia}`
  },
  {
    id: 11,
    image: `${locationBlueEconomy}18.jpg${altMedia}`
  },
  {
    id: 12,
    image: `${locationBlueEconomy}19.jpg${altMedia}`
  },
  {
    id: 13,
    image: `${locationBlueEconomy}20.jpg${altMedia}`
  },
  {
    id: 14,
    image: `${locationBooth}60.jpg${altMedia}`
  },
  {
    id: 14,
    image: `${locationBooth}61.jpg${altMedia}`
  },
  {
    id: 14,
    image: `${locationBooth}62.jpg${altMedia}`
  },
  {
    id: 14,
    image: `${locationBooth}63.jpg${altMedia}`
  },
  {
    id: 14,
    image: `${locationBooth}64.jpg${altMedia}`
  },
  {
    id: 14,
    image: `${locationBooth}65.jpg${altMedia}`
  },
  {
    id: 14,
    image: `${locationBooth}66.jpg${altMedia}`
  },
  {
    id: 14,
    image: `${locationBooth}67.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}26.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}27.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}28.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}29.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}30.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}31.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}32.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}33.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}34.jpg${altMedia}`
  },
  {
    id: 15,
    image: `${locationEnergyTransition}53.jpg${altMedia}`
  },
  {
    id: 16,
    image: `${locationHistoryCultureandHeritage}35.jpg${altMedia}`
  },
  {
    id: 16,
    image: `${locationHistoryCultureandHeritage}36.jpg${altMedia}`
  },
  {
    id: 16,
    image: `${locationHistoryCultureandHeritage}37.jpg${altMedia}`
  },
  {
    id: 16,
    image: `${locationHistoryCultureandHeritage}38.jpg${altMedia}`
  },
  {
    id: 16,
    image: `${locationHistoryCultureandHeritage}39.jpg${altMedia}`
  },
  {
    id: 17,
    image: `${locationHumanCapitalTechnologyInnovation}21.jpg${altMedia}`
  },
  {
    id: 17,
    image: `${locationHumanCapitalTechnologyInnovation}22.jpg${altMedia}`
  },
  {
    id: 17,
    image: `${locationHumanCapitalTechnologyInnovation}23.jpg${altMedia}`
  },
  {
    id: 17,
    image: `${locationHumanCapitalTechnologyInnovation}24.jpg${altMedia}`
  },
  {
    id: 17,
    image: `${locationHumanCapitalTechnologyInnovation}25.jpg${altMedia}`
  },
  {
    id: 18,
    image: `${locationSustainableliving}11.jpg${altMedia}`
  },
  {
    id: 18,
    image: `${locationSustainableliving}12.jpg${altMedia}`
  },
  {
    id: 18,
    image: `${locationSustainableliving}13.jpg${altMedia}`
  },
  {
    id: 18,
    image: `${locationSustainableliving}14.jpg${altMedia}`
  },
  {
    id: 18,
    image: `${locationSustainableliving}15.jpg${altMedia}`
  },
  {
    id: 19,
    image: `${locationPenonton}1.jpg${altMedia}`
  },
  {
    id: 19,
    image: `${locationPenonton}2.jpg${altMedia}`
  },
  {
    id: 19,
    image: `${locationPenonton}54.jpg${altMedia}`
  },
  {
    id: 19,
    image: `${locationPenonton}55.jpg${altMedia}`
  },
  {
    id: 19,
    image: `${locationPenonton}56.jpg${altMedia}`
  },
  {
    id: 19,
    image: `${locationPenonton}57.jpg${altMedia}`
  },
  {
    id: 19,
    image: `${locationPenonton}58.jpg${altMedia}`
  },
  {
    id: 19,
    image: `${locationPenonton}59.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}68.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}69.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}70.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}71.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}72.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}73.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}74.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}75.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}76.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}77.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}78.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}79.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}80.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}81.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}82.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}83.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}84.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}85.jpg${altMedia}`
  },
  {
    id: 20,
    image: `${locationWallofframe}86.jpg${altMedia}`
  },
  {
    id: 21,
    image: `${locationIntermezo}48.jpg${altMedia}`
  },
  {
    id: 21,
    image: `${locationIntermezo}49.jpg${altMedia}`
  },
  {
    id: 21,
    image: `${locationIntermezo}50.jpg${altMedia}`
  },
  {
    id: 21,
    image: `${locationIntermezo}51.jpg${altMedia}`
  },
  {
    id: 21,
    image: `${locationIntermezo}52.jpg${altMedia}`
  },
  {
    id: 22,
    image: `${locationClosing}40.jpg${altMedia}`
  },
  {
    id: 22,
    image: `${locationClosing}41.jpg${altMedia}`
  },
  {
    id: 22,
    image: `${locationClosing}42.jpg${altMedia}`
  },
  {
    id: 22,
    image: `${locationClosing}43.jpg${altMedia}`
  },
  {
    id: 22,
    image: `${locationClosing}44.jpg${altMedia}`
  },
  {
    id: 22,
    image: `${locationClosing}45.jpg${altMedia}`
  },
  {
    id: 22,
    image: `${locationClosing}46.jpg${altMedia}`
  },
  {
    id: 22,
    image: `${locationClosing}47.jpg${altMedia}`
  },
]

export default galleryAll;

