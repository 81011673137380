const statisticsWidget = [
  {
    id: 1,
    title: 'Participant',
    count: 1000,
  },
  {
    id: 2,
    title: 'Speakers',
    count: 20,
  },
  {
    id: 3,
    title: 'Sesi Acara',
    count: 5
  }
];

module.exports = statisticsWidget;
