import React from 'react'
// import TeaserVideo from '../../utils/Videos/Teasier.webm'
import Banner from '../../utils/Images/Banner/Banner.jpg'

const Introduction = () => {
  return (
    <section>
      <div id="carouselExample" class="carousel slide">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={Banner} class="d-block w-100" alt="..." />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Introduction;
